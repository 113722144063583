<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Requests') }}</div>
<!--            <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
          </template>
          <template #end>
            <div>
              <CustomDropdown id="supplier"
                              class="p-mr-2"
                              style="min-width: 180px; max-width: 200px;"
                              :disabled="dataTableIsLoading"
                              ref="selectedSupplierDropdown"
                              :searchData="supplierSearchData"
                              v-model="selectedSupplier"
                              :options="suppliersData"
                              filterPlaceholder="Find supplier"
                              optionLabel="company_name"
                              :filter="true"
                              :filterFields="['name', 'company_name', 'reg_number']"
                              :loading="suppliersDataIsLoading"
                              @change="changeSupplier"
                              @filter="searchSuppliers"
                              :showClear="true">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
                  </div>
                  <span v-else>Select supplier</span>
                </template>
                <template #option="slotProps">
                  <div v-if="slotProps.option">
                    <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
                  </div>
                </template>
              </CustomDropdown>
            </div>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
        <DesktopDataTable v-else-if="!isLoading"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Requests')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
                                {name: 'code', title: $t('Code'), sortable: true},
                                {name: 'name', title: $t('Name'), sortable: true},
                                {name: 'supplier', title: $t('Supplier'), sortable: false},
                                {name: 'qty', title: $t('Qty'), sortable: true},
                                {name: 'ordered_qty', title: 'Ordered', sortable: true},
                                {name: 'reduced_qty', title: 'Reduced', sortable: true},
                                {name: 'purchase_price', title: 'Buy', sortable: true},
                                {name: 'sell_price', title: 'Sell price', sortable: true},
                                {name: 'order', class: 'ti-hummer', width: '40px', sortable: false},
                                {name: 'car', title: $t('Car'), sortable: false},
                                {name: '', title: '', sortable: false},
                                {name: '', title: '', sortable: false},
                                {name: 'fixed_qty', title: '', class: 'ti-link', sortable: false},
                                {name: 'created_at', title: $t('Created'), sortable: true},
                            ]">
<!--          <template v-slot:multiselectHeader>-->
<!--            <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">-->
<!--              <Checkbox :disabled="allCurrentItemsAreAdded" @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>-->
<!--            </th>-->
<!--          </template>-->
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id), 'updated-item': isItemUpdated(item.id)}">
<!--              <td v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">-->
<!--                <Checkbox v-if="!isItemAdded(item.id)" :class="{ 'no-pointer':isItemAdded(item.id)}" :value="item.id" v-model="selectedItems"/>-->
<!--              </td>-->
              <td>
                <span v-if="item.code">{{ item.code }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
              </td>
              <td>{{ item.qty }}</td>
              <td>{{ item.ordered_qty }}</td>
              <td>{{ item.reduced_qty }}</td>
              <td>{{ formatCurrency(item.purchase_price) }}</td>
              <td>{{ formatCurrency(item.sell_price) }}</td>
              <td>
                <span v-if="item.order && item.order.number">
                  <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}"><i class="ti-hummer"></i></router-link>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.car">
                  <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}">
                    <i class="ti-car p-mr-1"></i>
                    <span v-if="item.car.make || item.car.model">{{ item.car.make.name }} {{ item.car.model.name }}</span>
                    <span v-if="item.car.plate_number">, {{ item.car.plate_number }}</span>
                  </router-link>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <RequestPositionStateButton :position="item" :orderId="item.order_id" @update-request-state-history="updateRequestPosition"/>
              </td>
              <td>
                <PositionHistoryButton :position="item"/>
              </td>
              <td>
                <PositionFixedQtyButton :position="item"/>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
<!--              <td>-->
<!--                <div class="p-d-flex">-->
<!--                  <EditButton :disabled="item.role === constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.superAdmin"-->
<!--                              @click="editItem(item)" />-->
<!--                </div>-->
<!--              </td>-->
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

			</div>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
// import RequestPositionStateButton from "@/components/RequestPositionStateButton";
import PositionHistoryButton from "@/components/PositionHistoryButton";
import RequestPositionStateButton from "@/pages/orders/components/RequestPositionStateButton";
import PositionFixedQtyButton from "@/components/PositionFixedQtyButton";

export default {
  mixins: [ httpMixins, datatableMixins, formatMixins ],
  components: { RequestPositionStateButton, PositionHistoryButton, PositionFixedQtyButton },
	data() {
		return {
      suppliers: null,
      fieldsExpand: 'order,car,request_state_history,history',
      apiLink: 'order-position/get-request-positions',
      routePath: '/requests',
      sort: {
        column: 'id',
        direction: '-',
      },
		}
	},
  watch: {
    suppliers(value) {
      if (value?.length) {
        this.suppliersData = [...value]
      } else {
        this.suppliersData = null
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    this.getSuppliers().then(() => this.checkSupplierInArray())
	},
  methods: {
    async updateRequestPosition(id) {
      this.showUpdatedItemPdfSpinner = true
      if (!id) return false
      this.updatedItemId = id

      let foundItem = this.items?.find(item => item.id === id)
      if (foundItem) {
        const indexOfFoundItem = this.items.indexOf(foundItem)
        if (indexOfFoundItem !== -1) {
          const itemData = await this.getItemById(id)
          if (itemData) {
            this.items[indexOfFoundItem] = itemData
          }
        }
      }
    },
    async getItemById(id) {
      try {
        const { data, status } = await httpClient.post(`order-position/get-by-id?expand=${this.fieldsExpand}`, { id })
        if (status === 200 && data?.success && data.item) {
          return data.item
        } else {
          return null
        }
      } catch(err) {
        this.showError(err)
      }
    },
    async getItems() {
      // console.log('get items')
      this.dataTableIsLoading = true
      try {
        const { status, data, headers } = await httpClient(`${this.apiLink}`, {
          params: {
            supplierId: this.selectedSupplierId,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    itemsArrayIsEmpty() {
        return !(this.items && this.items.length)
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 0
    },
  }
}
</script>

<style scoped lang="scss">

</style>